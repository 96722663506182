import { vertexShader, fragmentShader } from './tube';
import { colorBlue1Vec,  colorBlue2Vec,  colorBlue1FracVec,  colorBlue2FracVec, } from './tube';

AFRAME.registerComponent("chiller-out-cold-tube", {
  schema: {
      flowSpeed: { default: 0.2 }, // Speed of flow
      fraction: { default: 0.5 }, // fraction variable for the "heat" (color) of the cable
  },
  init: function () {
      const startColdFromAbove = new THREE.Vector3(-10.5, 1, 3);
      const endColdFromAbove = new THREE.Vector3(-9.8, -4, 3);
      const flowDirection = new THREE.Vector2(0, 1);
      const radius = 0.2;
      const coldPointsFromAbove = [
        endColdFromAbove,
        new THREE.Vector3(startColdFromAbove.x + 0.7, startColdFromAbove.y - 0.3, startColdFromAbove.z),
        new THREE.Vector3(startColdFromAbove.x + 0.7, startColdFromAbove.y - 0.3, startColdFromAbove.z),
        new THREE.Vector3(startColdFromAbove.x + 0.4, startColdFromAbove.y, startColdFromAbove.z),
        new THREE.Vector3(startColdFromAbove.x + 0.4, startColdFromAbove.y, startColdFromAbove.z),
        startColdFromAbove
      ];
      const coldCurveFromAbove = new THREE.CatmullRomCurve3(coldPointsFromAbove);
      const coldTubeGeometryFromAbove = new THREE.TubeGeometry(coldCurveFromAbove, 10, radius, 8, false);
      
      const coldFromAboveMaterial = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          flowDirection: { value: flowDirection },
          flowSpeed: { value: this.data.flowSpeed },
          stripeWidth: { value: 0.1 }, // Adjust stripe width as needed
          opacity: { value: 0.8 },
          fraction: { value: this.data.fraction },
          color1: { value: colorBlue1Vec },
          color2: { value: colorBlue2Vec },
          colorFrac1: { value: colorBlue1FracVec },
          colorFrac2: { value: colorBlue2FracVec },
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader
      });
      const coldTubeMeshFromAbove = new THREE.Mesh(coldTubeGeometryFromAbove, coldFromAboveMaterial);
      this.el.setObject3D("coldTubeMeshFromAbove", coldTubeMeshFromAbove);

      this.el.sceneEl.addEventListener("tick", this.tick.bind(this));
  },
   tick: function (time, deltaTime) {
      const dt = (deltaTime / 1000) * this.data.flowSpeed;
      // Update time uniforms for shader animation
      this.el.getObject3D("coldTubeMeshFromAbove").material.uniforms.time.value += dt;
      this.el.getObject3D("coldTubeMeshFromAbove").material.uniforms.fraction.value = this.data.fraction;
    },
});
