/*
export const colorRed1Vec = new THREE.Vector3(0.25, 0.25, 0.25);
export const colorRed2Vec = new THREE.Vector3(0.25, 0.25, 0.25);
export const colorRed1FracVec = new THREE.Vector3(0.75,-0.25,-0.25);
export const colorRed2FracVec = new THREE.Vector3(0.75, 0.00,-0.25);

export const colorBlue1Vec = new THREE.Vector3(0.25, 0.25, 0.25);
export const colorBlue2Vec = new THREE.Vector3(0.25, 0.25, 0.25);
export const colorBlue1FracVec = new THREE.Vector3(-0.25,-0.25, 0.75);
export const colorBlue2FracVec = new THREE.Vector3(-0.25, 0.00, 0.75);
//*/

export const colorRed1Vec = new THREE.Vector3(0.70, 0.70, 0.70);
export const colorRed2Vec = new THREE.Vector3(0.70, 0.70, 0.70);
export const colorRed1FracVec = new THREE.Vector3(0.30,-0.70,-0.70);
export const colorRed2FracVec = new THREE.Vector3(0.30,-0.45,-0.70);

export const colorBlue1Vec = new THREE.Vector3(0.70, 0.70, 0.70);
export const colorBlue2Vec = new THREE.Vector3(0.70, 0.70, 0.70);
export const colorBlue1FracVec = new THREE.Vector3(-0.70,-0.70, 0.30);
export const colorBlue2FracVec = new THREE.Vector3(-0.70,-0.45, 0.30);

export const vertexShader = `
varying vec2 vUv;
void main() {
 vUv = uv;
 gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;
export const fragmentShader = `
uniform float time;
uniform vec2 flowDirection;
uniform float flowSpeed;
uniform float stripeWidth; // Width of each stripe
varying vec2 vUv;
uniform float opacity; 
uniform float fraction;
uniform vec3 color1;
uniform vec3 color2;
uniform vec3 colorFrac1;
uniform vec3 colorFrac2;

void main() {
 // Calculate offset based on time, direction, and speed (unchanged)
 vec2 offset = vUv + flowDirection * time * flowSpeed;

 // Calculate stripe pattern based on UV coordinates and time
 float stripePosition = mod(offset.x + time * flowSpeed, stripeWidth * 2.0);
 float stripe = step(stripeWidth, stripePosition) - step(stripeWidth * 2.0, stripePosition);

 // Set red and other red color based on stripe pattern
 vec3 col1 = vec3(color1.x + fraction * colorFrac1.x, color1.y + fraction * colorFrac1.y, color1.z + fraction * colorFrac1.z);
 vec3 col2 = vec3(color2.x + fraction * colorFrac2.x, color2.y + fraction * colorFrac2.y, color2.z + fraction * colorFrac2.z);
 vec3 color = mix(col1, col2, stripe); // Mix colors based on stripe pattern

 // Output the color with opacity
 gl_FragColor = vec4(color, opacity);
}
`;
